<template>
<v-col id="toolbar-players">
  <!------------------------------------------------------- GRILLE DE JEU -->
  <v-row v-if="gameData != null && gameData.error != true && !gameData.isEnded">
    <v-col cols="12" sm="10" md="7" lg="5" class="mx-auto text-left py-0" 
          :style="'width:'+gridSize+'px !important;'">
      <v-toolbar dense color="transparent" elevation="0" height="30">
        <v-icon small color="blue" class="ml-1 mr-3 mb-1">mdi-sail-boat</v-icon>
        <span class="font-led blue--text">{{ p1NbBoatClosed }}/{{ gameData.nbBoat }}</span>
        <v-spacer/>
        <v-icon small color="primary" class="mr-2 mb-2">mdi-hand-coin</v-icon>
        <span class="font-led primary--text">{{ gameData.amountBet }} PRT</span>
        <v-spacer/>
        <span class="font-led blue--text">{{ p2NbBoatClosed }}/{{ gameData.nbBoat }}</span>
        <v-icon small color="blue" class="ml-1 mb-1">mdi-sail-boat</v-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar dense color="transparent" elevation="0" height="36" class="mt-2">
        <v-btn @click="setCurrentGrid(grid1)"  rounded :disabled="mode=='setting' && !iamPlayer1" class="pl-1 pr-2"
                :color="currentPlayerIs1 ? 'primary' : ''" :class="currentPlayerIs1 ? 'secondary--text' : ''">
          <v-icon class="mr-1">mdi-pirate</v-icon> J1 • {{ player1Name }}
        </v-btn>
        <v-spacer/>
        <span class="font-led primary--text">VS</span>
        <v-spacer/>
        <v-btn @click="setCurrentGrid(grid2)"  rounded :disabled="mode=='setting' && iamPlayer1" class="pr-1 pl-2"
                :color="currentPlayerIs2 ? 'primary' : ''" :class="currentPlayerIs2 ? 'secondary--text' : ''"
                :title="gameData.gameMode == 'solo' ? 'Capitaine ' + gameData.ghostName : player2Name">
          {{ gameData.gameMode == "solo" ? gameData.ghostName : player2Name }} • J2 <v-icon class="ml-1">mdi-pirate</v-icon>
        </v-btn>
      </v-toolbar>
    </v-col>

    <v-col cols="12" class="mx-auto px-0 pt-2">
      <div class="mx-auto d-block" :style="'height:'+gridSize+'px;width:'+gridSize+'px;'">

        <table v-if="inited" @mouseout="onMouseOutGride">

          <thead>
            <tr>
              <th class="gridBox d-inline-flex" 
                  :style="'height:'+gridBoxSize+'px;width:'+gridBoxSize+'px;'">
              </th>
              <template v-for="x in gameData.config.gridFactor">
                <th :key="x" class="gridBox d-inline-flex" 
                    :style="'height:'+gridBoxSize+'px;width:'+gridBoxSize+'px;'">
                    <span>{{ x }}</span>
                </th>
              </template>
            </tr>
          </thead>

          <template v-for="x in gameData.config.gridFactor">
            <tr :key="x">
              <td class="gridBox d-inline-flex" 
                  :style="'height:'+gridBoxSize+'px;width:'+gridBoxSize+'px;'">
                  <span>{{ numToAlpha(x) }}</span>
              </td>
              <template v-for="y in gameData.config.gridFactor">
                <td :key="y" class="gridBox d-inline-flex" 
                    :style="'height:'+gridBoxSize+'px;width:'+gridBoxSize+'px;'"
                    :class="lastShot.x == x && lastShot.y == y ? 'lastShot' : ''"
                    @mouseenter="onMouseEnter(x, y)"
                    @dblclick="onDblClickGridBox(x, y)"
                    @click="onClickGridBox(x, y)">
                    <span class="boxRef" :style="'height:'+(gridBoxSize-2)+'px;width:'+(gridBoxSize-2)+'px;'"
                          :class="(currentBoxX==x && currentBoxY==y ? 'isCurrentBox':'') + ' ' + (mode == 'setting' && (!newBoatSelectionOk || newBoatCollision) ? 'boxHidden' : '')">
                      {{ numToAlpha(x) }}{{ y }}
                    </span>
                    <span v-if="mode == 'setting' && (!newBoatSelectionOk || newBoatCollision)" class="boxRef">
                      <v-icon class="" color="red" small>mdi-close</v-icon>
                    </span>
                    <template v-if="currentGrid[x] != null">
                      <template v-if="currentBoxX!=x||currentBoxY!=y">
                        <template v-if="mode == 'setting'">
                          <template v-if="currentBoxHoverX == currentBoxX && currentGrid[x][y].value == 0">
                            <v-icon v-if="x == currentBoxHoverX && y > currentBoxHoverY && y < currentBoxY" class="boxIcon" :color="newBoatSizeOk && !newBoatCollision ? newBoatColor : 'red'">mdi-{{ newBoatSizeOk && !newBoatCollision ? 'square' : 'close' }}</v-icon>
                            <v-icon v-if="x == currentBoxHoverX && y < currentBoxHoverY && y > currentBoxY" class="boxIcon" :color="newBoatSizeOk && !newBoatCollision ? newBoatColor : 'red'">mdi-{{ newBoatSizeOk && !newBoatCollision ? 'square' : 'close' }}</v-icon>
                          </template>
                          <template v-if="currentBoxHoverY == currentBoxY && currentGrid[x][y].value == 0">
                            <v-icon v-if="y == currentBoxHoverY && x > currentBoxHoverX && x < currentBoxX" class="boxIcon" :color="newBoatSizeOk && !newBoatCollision ? newBoatColor : 'red'">mdi-{{ newBoatSizeOk && !newBoatCollision ? 'square' : 'close' }}</v-icon>
                            <v-icon v-if="y == currentBoxHoverY && x < currentBoxHoverX && x > currentBoxX" class="boxIcon" :color="newBoatSizeOk && !newBoatCollision ? newBoatColor : 'red'">mdi-{{ newBoatSizeOk && !newBoatCollision ? 'square' : 'close' }}</v-icon>
                          </template>
                          <v-icon v-if="currentGrid[x][y].value == 1" class="boxIcon" :color="currentGrid[x][y].color">mdi-square</v-icon>
                        </template>
                        <template v-if="mode=='play'">
                          <template v-if="currentGrid[x][y].status == 'EMPTY' || currentGrid[x][y].status == 'RADAR_IN' || currentGrid[x][y].status == 'RADAR_OUT'">
                            <v-icon v-if="currentGrid[x][y].value == 0 && currentGrid[x][y].status == 'EMPTY'" class="boxIcon" color="grey darken-4" small>mdi-waves</v-icon>
                            <v-icon v-if="currentGrid[x][y].value == 1 && currentPlayerIsMe" class="boxIcon" :color="currentGrid[x][y].color" >mdi-square</v-icon>
                            <v-icon v-if="currentGrid[x][y].value == 1 && !currentPlayerIsMe && currentGrid[x][y].status != 'RADAR_IN' && currentGrid[x][y].status != 'RADAR_OUT'" class="boxIcon" color="grey darken-4" small>mdi-waves</v-icon>
                          </template>
                          <v-icon v-if="currentGrid[x][y].status == 'RADAR_IN' && (currentGrid[x][y].value == 0 || !currentPlayerIsMe)" class="boxIcon" color="orange" small>mdi-radar</v-icon>
                          <v-icon v-if="currentGrid[x][y].status == 'RADAR_OUT' && (currentGrid[x][y].value == 0 || !currentPlayerIsMe)" class="boxIcon" color="grey darken-2" small>mdi-radar</v-icon>
                          <v-icon v-if="currentGrid[x][y].status == 'TOUCH'" class="boxIcon" color="red" >mdi-nuke</v-icon>
                          <v-icon v-if="currentGrid[x][y].status == 'OUT'"   class="boxIcon" color="blue" small>mdi-liquid-spot</v-icon>
                        </template>
                      </template>
                    </template>
                </td>
              </template>
            </tr>
          </template>

        </table>

      </div>
    </v-col>


    <v-col cols="12" sm="10" md="6" lg="5" class="mx-auto px-5 pt-0">

      <template v-if="timerSec > 0 || timerLoading">
        <h1 class="primary--text text-center mb-5">{{ 4 - parseInt((timerSec+1000)/1000) }}</h1>
        <v-progress-linear color="primary" height="4" :value="timerSec/10/3" rounded></v-progress-linear>
      </template>

      <template v-else>

        <template v-if="mode == 'setting'">
          <h4 class="primary--text font-papyrus text-center mb-4">
            Placez vos bateaux • 
            {{ this.myBoats.length }} / {{ gameData.nbBoat }} 
          </h4>
          <v-row>
            <v-col cols="12" class="text-center">
              <b>Sélectionnez la première case,<br>puis la dernière de chaque bateau</b>
            </v-col>
          </v-row>
            <v-col cols="12" sm="12" md="12" class="mx-auto text-center">
              <v-alert v-if="newBoatSize == 1"  outlined dense color="grey">
                <v-icon color="grey">mdi-cancel</v-icon>
                Cliquer sur la case de départ pour annuler le bateau en cours
              </v-alert>
              <v-alert v-if="currentBoxX != 0 && newBoatSize > 1 && (!newBoatSelectionOk || !newBoatSizeOk || newBoatCollision)" 
                      outlined dense color="red"> 
                <v-icon color="red">mdi-close</v-icon>
                <template v-if="!newBoatSelectionOk">
                  Impossible de placer un bateau dans cette position
                </template>
                <template v-if="newBoatCollision">
                  Collision avec un autre bateau !
                </template>
                <template v-if="!newBoatSizeOk && newBoatSize > 1 && newBoatSelectionOk && !newBoatCollision">
                  Le bateau est trop long : max {{ gameData.config.maxSizeBoat }} cases
                </template>
              </v-alert>
              <v-alert v-if="(newBoatSelectionOk && newBoatSizeOk && !newBoatCollision) && currentBoxX != 0"
                      outlined dense color="green" > 
                <v-icon color="green">mdi-check</v-icon>
                Vous pouvez placer un bateau dans cette position
              </v-alert>
            </v-col>
          
        </template>

        <template v-if="enemyReady">
          <h5 v-if="mode == 'play' && iamNextPlayer" 
              class="primary--text text-center font-papyrus mb-1">
            C'est à vous de jouer
          </h5>

          <h5 v-if="mode == 'play' && !iamNextPlayer" 
              class="primary--text font-papyrus text-center mt-4">
            <v-icon color="primary">mdi-bomb</v-icon> Attendez l'attaque de votre adversaire
          </h5>
          <v-btn @click="setIaAttack()" v-if="mode == 'play' && !iamNextPlayer && gameData.gameMode=='solo'" loading
                  color="primary" outlined fab class="mt-4 mx-auto d-block">
          </v-btn>
        
          <template v-if="mode == 'play' && iamNextPlayer">
            <h4 v-if="this.currentBoxX > 0 && this.currentBoxY > 0" 
                class="primary--text text-center font-papyrus mb-3">
                Jouer en {{ currentBoxStr }}
            </h4>
            <h5 v-else title="Sélectionnez une case sur la grille de votre adversaire"
                class="primary--text text-center font-papyrus mb-3">
                Sélectionnez une case...
            </h5>
            <v-row>
              <v-col cols="6" sm="6" class="text-center">
                <v-btn  @click="shoot()" x-large block elevation="0" rounded color="primary darken-2"
                        :disabled="this.currentBoxX == 0 || this.currentBoxY == 0">
                  <v-icon>mdi-target</v-icon> Tirer
                </v-btn>
                <small>Tirer sur la case {{ this.currentBoxX > 0 && this.currentBoxY > 0 ? currentBoxStr : "..." }}</small>
              </v-col>
              <v-col cols="6" sm="6" class="text-center">
                <v-btn @click="radar()" x-large block elevation="0" rounded color="green"
                        :disabled="this.currentBoxX == 0 || this.currentBoxY == 0">
                  <v-icon>mdi-radar</v-icon> Radar
                </v-btn>
                <small>Sonder autour de la case {{ this.currentBoxX > 0 && this.currentBoxY > 0 ? currentBoxStr : "..." }}</small>
              </v-col>
            </v-row>
          </template>
        </template>

        <template v-else-if="mode=='play'">
          <h3 class="primary--text text-center font-papyrus mt-5">
            Attendez qu'un autre joueur rejoigne votre partie...
          </h3>
        </template>

      </template>

    </v-col>


    <v-dialog v-model="showModalGridAction" transition=""
                  max-width="500" content-class="">
      <v-card elevation="0" style="border-radius:0;">

        <v-toolbar color="transparent" elevation="0" dense class="pt-4 mb-5 px-5">
          <!-- <v-icon small color="red" class="pr-1">mdi-beta</v-icon>  -->
          <!-- <b class="primary--text">{{ numToAlpha(currentBoxX) }}{{ currentBoxY }}</b> -->
          <h2 v-if="this.currentBoxX > 0 && this.currentBoxY > 0" 
              class="primary--text font-papyrus">
              Jouer en {{ currentBoxStr }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn x-small fab outlined elevation="0" @click="showModalGridAction = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn @click="shoot()" x-large block elevation="0" 
                      rounded color="primary darken-2">
                <v-icon>mdi-target</v-icon> Tirer
              </v-btn>
              <small>Tirer sur la case {{ currentBoxStr }}</small>
            </v-col>
            <v-col cols="12" sm="6" class="text-center">
              <v-btn @click="radar()" x-large block elevation="0" rounded color="green">
                <v-icon>mdi-radar</v-icon> Radar
              </v-btn>
              <small>Sonder la zone autour de la case {{ currentBoxStr }}</small>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>

  </v-row>
  <!------------------------------------------------------- FIN DE PARTIE -->
  <v-row v-else-if="gameData != null && gameData.isEnded"> 
    <v-col cols="12" sm="8" md="6"  
           style="margin-top:100px;"
           class="mx-auto px-5 text-center header-section">

      <template v-if="gameData.userWiner != null && gameData.userWiner._id != $store.state.auth.user._id">
        <h3 class="mb-5 primary--text">Vous avez perdu !</h3>
        <br><br>
        <v-divider/>
        <br><br>
        <p v-if="gameData.gameMode == 'solo'" class="font-lg font-papyrus">
          Vainqueur :<br><b>Capitaine {{ gameData.ghostName }}</b>
        </p>
        <p v-else class="font-lg font-papyrus">
          Vainqueur :<br><b>{{ gameData.userWiner.name }}</b>
        </p>
        <h4 v-if="$store.state.auth.isLogin" class="font25px font-led red--text">
          Vous avez perdu {{ gameData.amountBet }} PRT
        </h4>
        <p v-else class="text-left font-papyrus">
          <br>
          <v-divider/>
          <br><br>
          Encore une bataille de perdue !
          Heureusement que cette partie comptait pour du beurre...<br><br>
          T'es pas encore prêt à jouer dans la cours des grands jeune pirate...
          Mais si tu veux quand-même tenter ta chance, <span class="green--text">créé ton compte*</span> dès maintenant, 
          et commence à jouer avec de la vraie Monnaie Pirate !!!<br>
          <small>* inscription 100% gratuite</small>
        </p>
         
      </template>
      <template v-else>
        <h3 class="mb-5 primary--text">Vous avez gagné !</h3>
        <br><br>
        <v-divider/>
        <br><br>
        <p class="font-lg font-papyrus">Félicitation !</p>
        <h4 class="font25px font-led primary--text">
          <template v-if="$store.state.auth.isLogin">Vous avez gagné {{ gameData.amountBet }} PRT !!!</template>
          <template v-else>
            Vous avez gagné la partie !
            <p class="text-left white--text font-papyrus">
            <br>
            <v-divider/>
            <br>
            Dommage, cette partie ne comptait que pour du beurre !<br><br>
            Mais tu as l'air déjà prêt à jouer dans la cours des grands jeune pirate...
            Si tu veux tenter ta chance, et commence à jouer avec de la vraie Monnaie Pirate,
            il te suffit de <span class="green--text">créé ton compte*</span> dès maintenant !!!<br><br>
            <small>* inscription 100% gratuite</small>
          </p>
          </template>
        </h4>
      </template>

    </v-col>
  </v-row>
  <!------------------------------------------------------- ERREUR -->
  <v-row v-else>
    <v-col v-if="gameData == null"
           cols="12" md="6" class="mx-auto text-center" 
           style="margin-top:100px;">
      <v-btn fab class="primary--text" :loading="true"></v-btn>
      <h1 class="primary--text">Chargement de la partie</h1>
    </v-col>
    <v-col v-else-if="gameData.error == true"
           cols="12" md="6" class="mx-auto text-center" 
           style="margin-top:100px;">
      <v-btn fab class="primary--text" @click="loadGameData">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <h1 class="primary--text">
        <v-icon color="primary">mdi-alert</v-icon> Partie introuvable
      </h1>
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="11" sm="8" md="5" lg="4" class="mx-auto text-center">

      <v-btn rounded class="mr-2" color="grey darken-4" 
            v-if="userEnemy != null && gameData != null && gameData.gameMode == 'multi'"
            @click="$store.dispatch('app/openConv', { userId: userEnemy._id })">
        <v-icon small class="mt-1 mr-1">mdi-message</v-icon> {{ userEnemy.name }}
      </v-btn>
      <v-divider class="my-5" />
      <v-btn @click="showDialogLegende = true"
             block large rounded elevation="0" class="mb-5">
        <v-icon>mdi-map-legend</v-icon>
        Afficher la légende
      </v-btn>
      <v-btn v-if="!$store.state.auth.isLogin" to="/register"
             block large rounded elevation="0" color="green">
        <v-icon>mdi-account-plus</v-icon>
        Créer mon compte gratuit
      </v-btn>
      <v-btn block large rounded class="mt-5" to="/game/battle/menu">
        <v-icon class="mr-1">mdi-arrow-left-circle</v-icon> 
        Retour au menu principal
      </v-btn>
      <v-btn block large rounded class="mt-5" to="/game/battle/create">
        <v-icon class="mr-1">mdi-sail-boat</v-icon> 
        Nouvelle partie
      </v-btn>
      <v-btn block large rounded class="mt-5" v-if="$store.state.auth.isLogin" to="/game/battle/load">
        <v-icon class="mr-1">mdi-download</v-icon> 
        Charger une partie
      </v-btn>
      <v-btn block large rounded class="mt-5" @click="loadGameData">
        <v-icon class="mr-1">mdi-refresh</v-icon> 
        Actualiser la grille
      </v-btn>
      <br><br><br><br><br><br><br><br>
    </v-col>
  </v-row>

  <v-dialog v-model="showDialogBoatKilled" max-width="500">
      <v-card class="text-center pa-0 dialog-sink" color="transparent" @click="showDialogBoatKilled=false">
        <v-img v-if="randImgSinkin==1" max-width="500" src="../../../assets/img/games/battle/sinkin/boat1.jpg"/>
        <v-img v-if="randImgSinkin==2" max-width="500" src="../../../assets/img/games/battle/sinkin/boat2.jpg"/>
        <v-img v-if="randImgSinkin==3" max-width="500" src="../../../assets/img/games/battle/sinkin/boat3.jpg"/>
        <v-img v-if="randImgSinkin==4" max-width="500" src="../../../assets/img/games/battle/sinkin/boat4.jpg"/>
        <v-img v-if="randImgSinkin==5" max-width="500" src="../../../assets/img/games/battle/sinkin/boat5.jpg"/>
        <v-img v-if="randImgSinkin==6" max-width="500" src="../../../assets/img/games/battle/sinkin/boat6.jpg"/>
        <v-img v-if="randImgSinkin==7" max-width="500" src="../../../assets/img/games/battle/sinkin/boat7.jpg"/>
        <v-img v-if="randImgSinkin==8" max-width="500" src="../../../assets/img/games/battle/sinkin/boat8.jpg"/>
        <v-img v-if="randImgSinkin==9" max-width="500" src="../../../assets/img/games/battle/sinkin/boat9.jpg"/>
        <v-img v-if="randImgSinkin==10" max-width="500" src="../../../assets/img/games/battle/sinkin/boat10.jpg"/>
        <div class="msg-sinkin-boat">
          <h2 v-if="!iamLastPlayer" class="font-papyrus red--text">
            <v-icon large color="red">mdi-nuke</v-icon><br>
            Vous venez de perdre un bateau !
          </h2>
          <h2 v-if="iamLastPlayer" class="font-papyrus primary--text">
            <v-icon large color="primary">mdi-nuke</v-icon><br>
            Vous venez de couler un bateau !
          </h2>
        </div>
      </v-card>
  </v-dialog>
  
    
  <v-dialog v-model="showDialogLegende" max-width="500" :fullscreen="$vuetify.breakpoint.width < 500">
      <v-card class="text-center pa-0 dialog-sink" color="transparent" @click="showDialogLegende=false">
        <v-card-title>La légende</v-card-title>
        <v-card-text class="text-left">
          <v-icon color="green">mdi-square</v-icon> Bateau longueur 2<br>
          <v-icon color="teal">mdi-square</v-icon> Bateau longueur 3<br>
          <v-icon color="light-green">mdi-square</v-icon> Bateau longueur 4<br>
          <v-icon color="indigo">mdi-square</v-icon> Bateau longueur 5<br>
          <v-icon color="grey darken-2">mdi-waves</v-icon> Case vide<br>
          <v-icon color="blue">mdi-liquid-spot</v-icon> Tir dans l'eau<br>
          <v-icon color="grey">mdi-radar</v-icon> Radar : aucun bateau dans la zone<br>
          <v-icon color="orange">mdi-radar</v-icon> Radar : au moins 1 case est occupée par un bateau<br>
          <v-icon color="red">mdi-nuke</v-icon> Touché !<br>
        </v-card-text>
      </v-card>
  </v-dialog>
  
    
</v-col>
</template>

<style>
.msg-sinkin-boat{
  position:absolute; 
  top: 40%; 
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 18px !important;
  right: 5%;
  left: 5%;
  width:90%;
  padding: 20px;
}
.dialog-sink .v-image{
  border: 3px solid rgb(236, 202, 10) !important;
}

#toolbar-players .v-toolbar__content{
  padding: 0px !important;
}

@media (min-width: 600px) {
  .route-game-battle-play .v-dialog{
    margin-left:-35px;
  }
}

@media (max-width: 600px) {
  .msg-sinkin-boat{
    top: 30%; 
  }
}

</style>

<script>

import axios from 'axios'
import '@/assets/css/game-battle.css';
import router from '../../../router/router'
//import core from '../plugins/core.js'

export default {
  name: 'game',
  components: {  },
  data: () => ({
    mode: 'setting',
    inited: false,
    gameData: null,
    maxSizeBoat: 5,
    showModalGridAction: false,
    gridFactor: 10,
    gridMaxSize: 500,
    
    currentBoxX: 0,
    currentBoxY: 0,
    currentBoxHoverX: 0,
    currentBoxHoverY: 0,

    grid1: [],
    grid2: [],
    currentGrid: null,

    gridMine: [],
    gridEnemy: [],
    myBoats: [],
    enemyBoats: [],

    timer: null,
    timerSec: 0,
    timerLoading: false,
    
    lastShot: { x: 0, y: 0 },

    nbb1: 0,
    nbb2: 0,

    showDialogBoatKilled: false,
    showDialogLegende: false,
    randImgSinkin: 1

  }),
 async mounted(){
    this.$store.dispatch('app/incStat', '/game/battle/play')

    this.loadGameData()

    this.$root.$off('game-battle-new-shoot')
              .$on('game-battle-new-shoot', (data) => { 
      if(this.gameData._id == data.game._id){
        //mémorise le nombre de bateau de chaque joueur
        let nbb1 = this.p1NbBoatClosed
        let nbb2 = this.p2NbBoatClosed
        //reinitialise le game avec les nouvelle donnée
        this.initGame(data.game)
        //programme le changement de grille pour dans 3 secondes
        this.setGridToPlay(3)
        //affiche un message quand un nouveau bateau vient d'être coulé && quand c'est pas la fin de la partie
        this.showDialogBoatKilled = (this.p1NbBoatClosed != 0 && nbb1 < this.p1NbBoatClosed && this.p1NbBoatClosed < this.gameData.nbBoat)
                                 || (this.p2NbBoatClosed != 0 && nbb2 < this.p2NbBoatClosed && this.p2NbBoatClosed < this.gameData.nbBoat)
      }

    })
    
  },
  methods: {
    loadGameData(){
      //this.gameData = null
      axios.get('/game/battle/game/'+this.$route.params.gameId)
           .then((res)=>{
            if(res.data.error == false && res.data.game != null){

            if(res.data.game.gameMode == 'solo' 
            && res.data.game.player1 != null 
            && res.data.game.player1._id != this.$store.state.auth.user._id){
              router.push('/dashboard')
              return
            }
              this.initGame(res.data.game)
              this.setCurrentGrid(this.gridMine)
              this.setGridToPlay(0)
              this.inited = true
            }else{
              this.gameData = res.data
            }
          })
    },
    initGame(game){
      this.gameData = game
      this.grid1 = game.grid1
      this.grid2 = game.grid2

      if(this.gameData.player1 == null) { 
        this.gridMine = this.grid1; this.gridEnemy = this.grid2; this.myBoats = this.gameData.boats1; this.enemyBoats = this.gameData.boats2   
      }
      else if(this.gameData.player1._id == this.$store.state.auth.user._id){ 
        this.gridMine = this.grid1; this.gridEnemy = this.grid2; this.myBoats = this.gameData.boats1; this.enemyBoats = this.gameData.boats2  
      }
      else if(this.gameData.player2._id == this.$store.state.auth.user._id){ 
          this.gridMine = this.grid2; this.gridEnemy = this.grid1; this.myBoats = this.gameData.boats2; this.enemyBoats = this.gameData.boats1  
      }

      console.log("nbBoat ???", this.gameData.nbBoat, this.myBoats.length)
      this.mode = (this.gameData.nbBoat == this.myBoats.length) ? "play" : "setting"

      this.currentBoxX = 0
      this.currentBoxY = 0
      this.currentBoxHoverX = 0
      this.currentBoxHoverY = 0

      this.randImgSinkin = ((Math.random() * 9) + 1).toFixed(0)
    },
    numToAlpha(x){
      return  String.fromCharCode(64 + x)
    },
    onMouseOutGride(){

    },
    onMouseEnter(x, y){
        this.currentBoxHoverX = x
        this.currentBoxHoverY = y
    },
    onDblClickGridBox(x, y){
      this.onClickGridBox(x, y)
      //this.showModalGridAction = true
    },
    onClickGridBox(x, y){
      console.log("onClickGridBox", this.mode, x, y, this.currentBoxX, this.currentBoxY, this.currentGrid[x][y].status)
      if(this.currentGrid[x][y].status!="EMPTY"
      && this.currentGrid[x][y].status!="RADAR_IN") return

      if(this.mode == "setting"){
        if (this.currentBoxX != 0 
        && (this.currentBoxX == x || this.currentBoxY == y)){
          if(this.putBoat(this.currentBoxX, this.currentBoxY, x, y)){
            this.currentBoxX = 0
            this.currentBoxY = 0
          }
          if(this.currentBoxX == x && this.currentBoxY == y) {
            this.currentBoxX = 0
            this.currentBoxY = 0
          }
        }
        else{
          console.log("coors", this.currentBoxX, x, this.currentBoxY, y)
          if(!this.newBoatCollision && this.newBoatSelectionOk) {
            this.currentBoxX = x
            this.currentBoxY = y
          }

        }
      }else if(this.mode == "play" && !this.currentPlayerIsMe){
        this.currentBoxX = x
        this.currentBoxY = y
      }
    },
    putBoat(x1, y1, x2, y2){
      console.log("putBoat", x1, y1, x2, y2)
      //vérifie la taille du nouveau bateau
      if(!this.newBoatSizeOk || this.newBoatCollision || !this.newBoatSelectionOk) 
        return false

      axios.post('/game/battle/put-boat/', {
                    gameId : this.$route.params.gameId,
                    x1: x1, y1: y1, x2: x2, y2: y2
            })
           .then((res)=>{
            console.log("res /game/battle/put-boat/", res.data)
            if(res.data.error == false && res.data.game != null){
              this.initGame(res.data.game)
              this.setGridToPlay(0)
            }
          })

      return true
    },
    setCurrentGrid(grid){
      this.currentGrid = grid
    },
    setGridToPlay(nbSec){ //s'il y a tous les bateaux de posés
      //if(nbSec > 0) nbSec++
      nbSec = nbSec * 1000
      console.log("setGridToPlay", nbSec, this.myBoats)

      if(this.myBoats.length == this.gameData.nbBoat)
        this.currentGrid = (this.gameData.lastPlayer == 1) ? this.grid2 : this.grid1
      
      if(this.iamPlayer1 && this.mode == "setting")
        this.currentGrid = this.grid1

      if(!this.iamPlayer1 && this.mode == "setting")
        this.currentGrid = this.grid2

      if(this.gameIsStarted){
        this.timerLoading = true
        let delay = nbSec == 0 ? 1 : 100
        this.timerSec = 0
        this.timer = setInterval(()=>{
          this.waitinTimer(nbSec)
        }, delay)
      }
        
    },

    waitinTimer(nbSec){
      if(this.timerSec == nbSec){
        if(this.myBoats.length == this.gameData.nbBoat)
              this.currentGrid = (this.gameData.lastPlayer == 1) ? this.grid1 : this.grid2
        else this.currentGrid = this.gridMine

        if(!this.iamNextPlayer && this.gameData.gameMode == "solo")
          setTimeout(()=>{ this.setIaAttack() }, 3000)

        this.lastShot = { x: 0, y: 0 }
        this.timerSec = 0
        this.timerLoading = false
        clearInterval(this.timer)
        this.timer = null
      }
      else this.timerSec+=100
    },
    
    shoot(){
      let params = { gameId: this.$route.params.gameId, 
                     target: { x: this.currentBoxX, y: this.currentBoxY } }

      axios.post('/game/battle/shoot', params)
          .then((res)=>{
            console.log("res", res)
            if(res.data.error == false && res.data.game != null){
              //mémorise le nombre de bateau de chaque joueur
              let nbb1 = this.p1NbBoatClosed
              let nbb2 = this.p2NbBoatClosed

              this.lastShot = res.data.lastShot
              this.initGame(res.data.game)
              this.setGridToPlay(3)
              //affiche un message quand un nouveau bateau vient d'être coulé
              this.showDialogBoatKilled = (this.p1NbBoatClosed != 0 && nbb1 < this.p1NbBoatClosed && this.p1NbBoatClosed < this.gameData.nbBoat)
                                       || (this.p2NbBoatClosed != 0 && nbb2 < this.p2NbBoatClosed && this.p2NbBoatClosed < this.gameData.nbBoat)

            }else{
              this.gameData = res.data
            }
        })
    },

    radar(){
      let params = { gameId: this.$route.params.gameId, 
                     target: { x: this.currentBoxX, y: this.currentBoxY } }

      axios.post('/game/battle/radar', params)
          .then((res)=>{
            console.log("res", res)
          if(res.data.error == false && res.data.game != null){
            this.initGame(res.data.game)
            this.setGridToPlay(3)
          }else{
            this.gameData = res.data
          }
        })
    },

    setIaAttack(){
      if(this.$route.params.gameId != null && !this.gameData.isEnded)
      axios.get('/game/battle/ia-attack/' + this.$route.params.gameId)
          .then((res)=>{
            console.log("res", res)
            if(res.data.error == false && res.data.game != null){
              //mémorise le nombre de bateau de chaque joueur
              let nbb1 = this.p1NbBoatClosed
              let nbb2 = this.p2NbBoatClosed

              this.lastShot = res.data.lastShot
              this.initGame(res.data.game)
              this.setGridToPlay(3)
              //affiche un message quand un nouveau bateau vient d'être coulé
              this.showDialogBoatKilled = (this.p1NbBoatClosed != 0 && nbb1 < this.p1NbBoatClosed && this.p1NbBoatClosed < this.gameData.nbBoat)
                                       || (this.p2NbBoatClosed != 0 && nbb2 < this.p2NbBoatClosed && this.p2NbBoatClosed < this.gameData.nbBoat)
            }else{
              this.gameData = res.data
            }
          })
    },

  },
  computed: {
    gridBoxSize(){
      let widthTotal = this.$vuetify.breakpoint.width < this.gridMaxSize ? this.$vuetify.breakpoint.width : this.gridMaxSize
      let size = (widthTotal / (this.gameData.config.gridFactor+1))+1
      if(size > 50) size = 50
      return size-2
    },
    gridSize(){
      return this.gridBoxSize * (this.gameData.config.gridFactor+1) - 6
    },
    currentBoxStr(){
      return this.numToAlpha(this.currentBoxX) + "" + this.currentBoxY
    },
    currentPlayerIs1(){ return this.currentGrid != null ? this.currentGrid[0][0] == this.grid1[0][0] : false },
    currentPlayerIs2(){ return this.currentGrid != null ? this.currentGrid[0][0] == this.grid2[0][0] : false },
    currentPlayerIsMe(){ return this.currentGrid != null ? this.currentGrid[0][0] == this.$store.state.auth.user._id : false },
    
    iamPlayer1(){ return this.grid1[0] != null ? this.grid1[0][0] == this.$store.state.auth.user._id : false },
    iamPlayerNb()  { return this.iamPlayer1 ? 1 : 2 },
    iamLastPlayer(){ return this.gameData != null && this.gameData.lastPlayer == this.iamPlayerNb },
    iamNextPlayer(){ return this.gameData != null && this.gameData.lastPlayer != this.iamPlayerNb },

    enemyReady(){ return this.enemyBoats.length == this.gameData.nbBoat },
    userEnemy() { 
      if(this.gameData == null) return {} 
      return this.iamPlayer1 ? this.gameData.player2 : this.gameData.player1 },

    player1Name(){
      if(this.gameData.player1 == null) return "Moi"
      return this.gameData.player1._id == this.$store.state.auth.user._id ? "Moi" : this.gameData.player1.name
    },
    player2Name(){
      if(this.gameData.player2 == null) return "En attente"
      return this.gameData.player2._id == this.$store.state.auth.user._id ? "Moi" : this.gameData.player2.name
    },

    newBoatSize(){
      if(this.currentBoxX == 0) return 0
      let boatSize = (this.currentBoxX == this.currentBoxHoverX) ? this.currentBoxY - this.currentBoxHoverY : this.currentBoxX - this.currentBoxHoverX; 
      if(boatSize < 0) boatSize = boatSize * (-1)
      boatSize++
      return boatSize
    },

    newBoatColor(){
      return this.gameData.config.boatColors[this.newBoatSize]
    },

    newBoatSizeOk(){
      return this.newBoatSize <= this.maxSizeBoat && this.newBoatSize > 1
    },

    newBoatSelectionOk(){
      return this.currentBoxX == this.currentBoxHoverX || this.currentBoxY == this.currentBoxHoverY || this.currentBoxX == 0
    },

    newBoatCollision(){
      let collision = false
      if(this.currentBoxX == this.currentBoxHoverX){ //ligne verticale
        if(this.currentBoxY < this.currentBoxHoverY){ //de haut en bas
          for(let y = this.currentBoxY; y <= this.currentBoxHoverY; y++)
            if(this.currentGrid[this.currentBoxX][y].value == 1) collision = true //s'il y a déjà un bateau sur cette case : collision
        }
        if(this.currentBoxY > this.currentBoxHoverY){ //de bas en haut
          for(let y = this.currentBoxY; y >= this.currentBoxHoverY; y--)
            if(this.currentGrid[this.currentBoxX][y].value == 1) collision = true
        }
      }else if(this.currentBoxY == this.currentBoxHoverY){  //ligne horizontale
        if(this.currentBoxX < this.currentBoxHoverX){ //de gauche à droite
          for(let x = this.currentBoxX; x <= this.currentBoxHoverX; x++)
            if(this.currentGrid[x][this.currentBoxY].value == 1) collision = true
        }
        if(this.currentBoxX > this.currentBoxHoverX){ //de droite à gauche
          for(let x = this.currentBoxX; x >= this.currentBoxHoverX; x--)
            if(this.currentGrid[x][this.currentBoxY].value == 1) collision = true
        }
      }
      return collision
    },

    p1NbBoatClosed(){
      if(this.gameData == null) return 0
      let nb = this.gameData.boats1.filter( boat => boat.isClosed ).length
      return nb
    },
    p2NbBoatClosed(){
      if(this.gameData == null) return 0
      let nb = this.gameData.boats2.filter( boat => boat.isClosed ).length
      return nb
    },

    gameIsStarted(){ return this.gameData.boats1.length == this.gameData.nbBoat && this.gameData.boats2.length == this.gameData.nbBoat }

  },
  watch: {
    
  }, 
}
</script>
